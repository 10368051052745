import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import AppDataContext from "../../contexts/AppDataContext";
import styles from "./DisplayVideo.module.css";

function DisplayVideo({ videoDisplayRef, timeValue, onTimeUpdate }) {
  const { videoFileURL, subtitleSrc, handleSubtitleTrack, Subtitle, isAuth } =
    useContext(AppDataContext);

  useEffect(() => {
    handleSubtitleTrack(); // eslint-disable-next-line
  }, [Subtitle]);

  useEffect(() => {
    if (videoDisplayRef.current) {
      videoDisplayRef.current.currentTime = timeValue;
    } // eslint-disable-next-line
  }, [timeValue]);

  return (
    <div className={styles.mainContainer}>
      {isAuth && videoFileURL && (
        <video
          ref={videoDisplayRef}
          src={videoFileURL}
          className={styles.videoFile}
          controls
          preload="metadata"
          onTimeUpdate={(event) => onTimeUpdate(event.target.currentTime)}
        >
          <track
            label="English"
            kind="subtitles"
            srcLang="en"
            src={subtitleSrc}
            default
          />
        </video>
      )}
      {!isAuth && (
        <div className={styles.loginLink}>
          <Link to="/login">Want to Upload a video? Login first</Link>
        </div>
      )}
    </div>
  );
}

export default DisplayVideo;
