import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import styles from "./Time.module.css";
import { useContext, useEffect, useState } from "react";
import AppDataContext from "../../../contexts/AppDataContext";

function Time({ property }) {
  const [isNegative, setIsNegative] = useState({
    start_time: false,
    end_time: false,
  });
  const {
    handleSubtitleChange,
    handleUpdate,
    isFormatted,
    isValidTime,
    panelItem,
  } = useContext(AppDataContext);
  const [item, setItem] = useState(panelItem);
  const [checkItem, setCheckItem] = useState(panelItem);

  useEffect(() => {
    setItem(panelItem);
    setCheckItem(panelItem); // eslint-disable-next-line
  }, [panelItem]);

  const fullUpdate = (property, eventValue) => {
    setCheckItem({ ...checkItem, [property]: eventValue });
    handleSubtitleChange({ ...item, [property]: eventValue });
    handleUpdate(item.id, property, eventValue);
  };

  const handleChange = (event, property) => {
    setItem({ ...item, [property]: event.target.value });
  };

  const handleTimeUpdate = (event, property) => {
    let eventValue = event.target.value;
    if (isFormatted(eventValue) && isValidTime(eventValue, property, item.id)) {
      fullUpdate(property, eventValue);
    } else {
      setItem(checkItem);
    }
  };

  const handleDecrement = (timeProperty) => {
    let firstPart = item[timeProperty].substr(0, 6);
    let secondPart = Number(item[timeProperty].substr(6, 6)) - 0.5;
    if (secondPart < 0) {
      setIsNegative[timeProperty] = true;
    } else {
      secondPart = secondPart.toFixed(3).padStart(6, "0");
      let decrementedTime = firstPart + secondPart;
      setItem({ ...item, [timeProperty]: decrementedTime });
      fullUpdate(timeProperty, decrementedTime);
    }
  };

  const handleIncrement = (timeProperty) => {
    let firstPart = item[timeProperty].substr(0, 6);
    let secondPart = Number(item[timeProperty].substr(6, 6)) + 0.5;
    secondPart = secondPart.toFixed(3).padStart(6, "0");
    let incrementedTime = firstPart + secondPart;

    setItem({ ...item, [timeProperty]: incrementedTime });
    fullUpdate(timeProperty, incrementedTime);
  };

  return (
    <div className={styles.mainContainer}>
      <button
        className={
          property === "start_time"
            ? styles.inputButton + " " + styles.inputButtonLeft
            : styles.inputButton +
              " " +
              styles.inputButtonLeft +
              " " +
              styles.blackBg
        }
        disabled={item.id === "" || isNegative[property]}
        onClick={() => handleDecrement(property)}
      >
        <FontAwesomeIcon icon={faMinus} />
      </button>
      <input
        className={
          property === "start_time"
            ? styles.timeInput
            : styles.timeInput + " " + styles.blackBg
        }
        type="text"
        name={property}
        id={property}
        value={item[property]}
        disabled={item[property] === ""}
        placeholder="00:00:00.000"
        onChange={(event) => handleChange(event, property)}
        onBlur={(event) => {
          handleTimeUpdate(event, property);
        }}
      />
      <button
        className={
          property === "start_time"
            ? styles.inputButton
            : styles.inputButton + " " + styles.blackBg
        }
        disabled={item.id === ""}
        onClick={() => handleIncrement(property)}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </div>
  );
}

export default Time;
