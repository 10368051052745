import { useContext } from "react";
import AppDataContext from "../../contexts/AppDataContext";
import styles from "./SingleColumnFocused.module.css";

function SingleColumnFocused({ item }) {
  const { handleSubtitleChange, handleUpdate } = useContext(AppDataContext);
  const handleChange = (event) => {
    handleSubtitleChange({ ...item, original_transcript: event.target.value });
  };

  return (
    <div className={styles.mainContainer}>
      <textarea
        className={styles.transcript}
        value={item.original_transcript}
        rows={4}
        name="original_transcript"
        onChange={handleChange}
        onBlur={(event) =>
          handleUpdate(item.id, event.target.name, event.target.value)
        }
      />
    </div>
  );
}

export default SingleColumnFocused;
