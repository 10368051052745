import { useContext, useEffect, useRef, useState } from "react";
import AppDataContext from "../../../contexts/AppDataContext";
import FocusedItem from "../../FocusedItem";
import LoadingStatus from "../../LoadingStatus";
import styles from "./SubtitleArea.module.css";
import SingleMobileFocused from "../SingleMobileFocused";

function SubtitleArea() {
  const itemHeight = 90;
  const listRef = useRef();
  const { Subtitle, setPanelItemValue, isLoading } = useContext(AppDataContext);
  const [activeItemId, setActiveItemId] = useState("");
  const [isSingleColumn, setIsSingleColumn] = useState(false);

  useEffect(() => {
    if (Subtitle[0]?.translated_transcript === "") {
      setIsSingleColumn(true);
    }
    return () => {
      setIsSingleColumn(false);
    };
  }, [Subtitle]);

  const handleFocus = (item, index) => {
    setActiveItemId(item.id);
    setPanelItemValue(item);
    scrollToItem(index);
  };

  const scrollToItem = (index) => {
    if (listRef.current) {
      const containerHeight = listRef.current.clientHeight;
      const scrollPosition =
        index * itemHeight - containerHeight / 2 + itemHeight / 2;
      listRef.current.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={styles.mainContainer} ref={listRef}>
      {isLoading.condition ? (
        <div className={styles.loadingContainer}>
          <LoadingStatus loadingMessage={isLoading.message} />
        </div>
      ) : (
        Subtitle.map((item, index) =>
          isSingleColumn ? (
            item && item.id === activeItemId ? (
              <SingleMobileFocused key={item.id} item={item} />
            ) : (
              <div
                key={item.id}
                className={styles.listItem}
                onFocus={() => handleFocus(item, index)}
              >
                <div className={styles.transcript}>
                  <textarea
                    className={styles.singleTextarea}
                    rows={3}
                    name="original_transcript"
                    defaultValue={item.original_transcript}
                  />
                </div>
              </div>
            )
          ) : item && item.id === activeItemId ? (
            <FocusedItem key={item.id} item={item} />
          ) : (
            <div
              key={item.id}
              className={styles.listItem}
              onFocus={() => handleFocus(item, index)}
            >
              <div className={styles.transcript}>
                <textarea
                  className={styles.textarea}
                  rows={3}
                  name="original_transcript"
                  defaultValue={item.original_transcript}
                />
              </div>
              <div className={styles.transcript + " " + styles.translated}>
                <textarea
                  className={styles.textarea}
                  rows={3}
                  name="translated_transcript"
                  defaultValue={item.translated_transcript}
                />
              </div>
            </div>
          )
        )
      )}
    </div>
  );
}

export default SubtitleArea;
