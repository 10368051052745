import PropTypes from "prop-types";
import styles from "./SubtitleRow.module.css";

function SubtitleRow({ children }) {
  const subtitleRow = (
    <form>
      <div className={"row justify-content-right " + styles.subtitleRow}>
        <div className={"col-5 " + styles.originalColumn}>
          <textarea
            className={"form-control " + styles.transcript}
            name="original_transcript"
            id="originalTranscript"
            rows={3}
            value={children.original_transcript}
            readOnly
          />
        </div>
        <div className={"col-5 " + styles.translateColumn}>
          <textarea
            className={
              "form-control " + styles.transcript + " " + styles.farsiDirection
            }
            name="translated_transcript"
            id="translatedTranscript"
            rows={3}
            value={children.translated_transcript}
            readOnly
          />
        </div>
        <div className={"col-2 " + styles.timeColumn}>
          <div className={styles.timeRowsContainer}>
            <div className={"row " + styles.timeRows}>
              <input
                className={"form-control " + styles.time}
                type="text"
                name="start_time"
                id="startTime"
                value={children.start_time}
                readOnly
              />
            </div>
            <div className={"row " + styles.timeRows}>
              <input
                className={
                  "form-control " + styles.time + " " + styles.blackTime
                }
                type="text"
                name="end_time"
                id="EndTime"
                value={children.end_time}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
  return <div className={styles.mainContainer}>{subtitleRow}</div>;
}

SubtitleRow.propTypes = {
  children: PropTypes.shape({
    id: PropTypes.string,
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    original_transcript: PropTypes.string || null,
    translated_transcript: PropTypes.string || null,
  }),
};

export default SubtitleRow;
