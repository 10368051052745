import { useNavigate } from "react-router-dom";
import mobileImage from "../../../data/images/404PageMobile 2.png";
import logoImage from "../../../data/images/FilmEditor-main.png";
import styles from "./Mobile404.module.css";

function Mobile404() {
  const navigate = useNavigate();
  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}>
        <img src={logoImage} alt="Page Logo" className={styles.logoImage} />
      </div>
      <div className={styles.bodyContainer}>
        <img
          src={mobileImage}
          alt="404 Page not Found"
          className={styles.bodyImage}
        />
      </div>
      <div className={styles.footerContainer}>
        <button className={styles.homepageButton} onClick={() => navigate("/")}>
          Home Page
        </button>
      </div>
    </div>
  );
}

export default Mobile404;
