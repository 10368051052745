import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Delete.module.css";

function Delete({ item, handle }) {
  return (
    <div className={styles.mainContainer}>
      <button
        className={styles.button}
        disabled={item?.id === ""}
        onClick={() => handle(item)}
      >
        <FontAwesomeIcon icon={faTrash} />
        &#160;&#160;&#160;Delete
      </button>
    </div>
  );
}

export default Delete;
