import { useContext, useEffect, useState } from "react";
import { MdOutlineTranslate } from "react-icons/md";
import styles from "./FocusedItem.module.css";
import AppDataContext from "../../contexts/AppDataContext";
import axios from "axios";

function FocusedItem({ item }) {
  // eslint-disable-next-line
  const [translatedTranscript, setTranslatedTranscript] = useState(
    item.translated_transcript
  );
  const translateEndpoint = "/transcript/translate/";
  const { handleUpdate, baseURL, handleSubtitleChange } =
    useContext(AppDataContext);

  useEffect(() => {
    setTranslatedTranscript(item.translated_transcript);
  }, [item]);

  const handleChange = (event, property) => {
    handleSubtitleChange({ ...item, [property]: event.target.value });
    if (property === "translated_transcript") {
      setTranslatedTranscript(event.target.value);
    }
  };

  const handleTranslation = (event, translateItem) => {
    event.preventDefault();
    axios
      .post(
        `${baseURL}${translateEndpoint}`,
        { text: event.currentTarget.original_transcript.value },
        { withCredentials: true }
      )
      .then((res) => {
        handleSubtitleChange({
          ...translateItem,
          translated_transcript: res.data.translated_text,
        });
        handleUpdate(
          translateItem.id,
          "translated_transcript",
          res.data.translated_text
        );
        setTranslatedTranscript(res.data.translated_text);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={styles.mainContainer}>
      <form
        onBlur={(event) =>
          handleUpdate(item.id, event.target.name, event.target.value)
        }
        onSubmit={(event) => handleTranslation(event, item)}
      >
        <div className={styles.rowContainer}>
          <div className={styles.buttonContainer}>
            <button className={styles.button} type="submit">
              <MdOutlineTranslate className={styles.buttonIcon} />
            </button>
          </div>
          <div className={styles.textContainer}>
            <textarea
              className={"form-control " + styles.transcript}
              value={item.original_transcript}
              rows={3}
              name="original_transcript"
              onChange={(event) => handleChange(event, "original_transcript")}
            />
          </div>
          <div className={styles.textContainer}>
            <textarea
              className={
                "form-control " + styles.transcript + " " + styles.farsi
              }
              value={translatedTranscript}
              rows={3}
              name="translated_transcript"
              onChange={(event) => handleChange(event, "translated_transcript")}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default FocusedItem;
