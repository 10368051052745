import { MdMergeType } from "react-icons/md";
import styles from "./Merge.module.css";

function Merge({ item, handle }) {
  return (
    <div className={styles.mainContainer}>
      <button
        className={styles.button}
        // disabled={isFirst}
        onClick={() => handle(item, "back")}
      >
        <MdMergeType className={styles.mergeIcon} />
        &#160;&#160;&#160;&#160;&#160;&#160;Merge
      </button>
    </div>
  );
}

export default Merge;
