import { useContext } from "react";
import AppDataContext from "../../contexts/AppDataContext";
import InvertVerticalMerge from "../EditPanelComponents/InvertVerticalMerge";
import VerticalMerge from "../EditPanelComponents/VerticalMerge";
import LoadingStatus from "../LoadingStatus";
import SubtitleList from "../SubtitleList";
import styles from "./EditSubtitle.module.css";

function EditSubtitle() {
  const { isLoading } = useContext(AppDataContext);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.mergeContainer}>
        <VerticalMerge />
        <InvertVerticalMerge />
      </div>
      {isLoading.condition ? (
        <div className={styles.loadingContainer}>
          <LoadingStatus loadingMessage={isLoading.message} />
        </div>
      ) : (
        <SubtitleList />
      )}
    </div>
  );
}

export default EditSubtitle;
