import UploadForm from "../../UploadForm";
import styles from "./VideoPlayer.module.css";

function VideoPlayer({ videoUrl, subtitle }) {
  return (
    <div className={styles.mainContainer}>
      {videoUrl ? (
        <video src={videoUrl} controls className={styles.video}>
          <track
            label="English"
            kind="subtitles"
            srcLang="en"
            src={subtitle}
            default
          />
        </video>
      ) : (
        <div className={styles.uploadContainer}>
          <UploadForm />
        </div>
      )}
    </div>
  );
}

export default VideoPlayer;
