import { useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { io } from "socket.io-client";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import styles from "./MainEditor.module.css";

import EditSubtitle from "../EditSubtitle";
import DisplayVideo from "../DisplayVideo";
import EditPanel from "../EditPanel";
import VideoTimeline from "../VideoTimeline";
import ExportData from "../ExportData";
import MobileEditor from "../MobileView/MobileEditor";
import AppDataContext from "../../contexts/AppDataContext";
import logoImage from "../../data/images/FilmEditor-main.png";

function MainEditor() {
  const { state } = useLocation();
  const { file_id } = useParams();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const videoDisplayRef = useRef(null);
  const [notUploaded, setNotUploaded] = useState(true);
  const [keepOpen, setKeepOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [uploadDisable, setUploadDisable] = useState(true);
  const [videoTime, setVideoTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [timelineTime, setTimelineTime] = useState(0);
  const [fileInputLabel, setFileInputLabel] = useState(
    "You need to upload your video again! Click here to select"
  );
  const {
    uploadParams,
    socketURL,
    dataProvider,
    setIsLoadingValue,
    baseURL,
    refreshData,
    handleUpload,
  } = useContext(AppDataContext);
  const uploadFileEndpoint = "/transcript/upload_file/";

  useEffect(() => {
    if (uploadParams.task_id) {
      axios
        .patch(
          `${baseURL}${uploadFileEndpoint}`,
          { file_id: uploadParams.file_id, task_id: uploadParams.task_id },
          { withCredentials: true }
        )
        .then(() => {
          setIsLoadingValue({
            condition: true,
            message: "Loading the Subtitle editing rows ... ",
          });
          dataProvider("newFile");
        })
        .catch((err) => console.log("Patch file_id:", err));
    } else {
      if (isNaN(file_id)) {
        navigate("/status-not-found");
      } else {
        uploadParams.file_id = file_id;
        refreshData(file_id);
      }
    }
    return () => {
      setIsLoadingValue({ condition: false, message: "" });
    }; // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const socket = io(socketURL, { autoConnect: false });

    socket.on("connect", () => {
      console.log("Connected to server");
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });
    if (uploadParams.task_id) {
      socket.connect();
      socket.emit("subscribe", uploadParams.task_id);
    }

    socket.on("message", (data, callback) => {
      if (data.message_id) {
        if (!data.keep_open) {
          setKeepOpen(false);
        }
        dataProvider(data);
        setIsLoadingValue({ condition: false, message: "" });
      }
      if (callback) {
        callback({
          status: "received",
          message_id: data.message_id,
          task_id: data.task_id,
        });
      }
    });

    if (!keepOpen) {
      socket.disconnect();
    }
    return () => {
      socket.disconnect();
    }; // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
    return () => {
      setIsMobile(false);
    };
  }, []);

  const handleFileInputChange = (event) => {
    setUploadDisable(false);
    setFileInputLabel(event.target.value.split("\\")[2]);
  };

  useEffect(() => {
    if (videoDisplayRef.current) {
      setVideoDuration(videoDisplayRef.current.duration); // eslint-disable-next-line
    }
  }, [videoDisplayRef.current?.duration]);

  const setVideoPlayTime = (timeValue) => {
    setVideoTime(timeValue);
  };

  const setTimelinePlayTime = (timeValue) => {
    setTimelineTime(timeValue);
  };

  const handlePlayPause = (isPlaying) => {
    if (isPlaying) {
      videoDisplayRef.current.pause();
    } else {
      videoDisplayRef.current.play();
    }
  };

  const handleMute = () => {
    videoDisplayRef.current.muted = !videoDisplayRef.current.muted;
  };

  if (isMobile) return <MobileEditor />;

  return (
    <div className={"container-fluid " + styles.mainContainer}>
      <div className={"row justify-content-between " + styles.headerSection}>
        <div className="col-3">
          <div className={styles.logoContainer}>
            <img src={logoImage} alt="LOGO" className={styles.logo} />
          </div>
        </div>
        <div className="col-1">
          <div className={styles.iconColumn}>
            <div className={styles.headerIcon} onClick={() => navigate("/")}>
              <FontAwesomeIcon icon={faUser} className={styles.userIcon} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.editAreaContainer}>
        <EditSubtitle />
        <div className={styles.editPanelContainer}>
          <EditPanel />
        </div>
      </div>
      <div className={styles.displayVideoContainer}>
        {!uploadParams.task_id && notUploaded ? (
          <div className={styles.upload}>
            <form
              className={styles.form}
              onSubmit={(event) => {
                event.preventDefault();
                handleUpload(videoRef);
                setNotUploaded(false);
              }}
              encType="multipart/form-data"
            >
              <div>
                <div className={styles.inputContainer}>
                  <label className={styles.inputLabel} htmlFor="uploadVideo">
                    {fileInputLabel}
                  </label>
                  <input
                    className={styles.inputFile}
                    type="file"
                    name="uploadVideo"
                    ref={videoRef}
                    accept=".mp4"
                    onChange={(event) => handleFileInputChange(event)}
                    id="uploadVideo"
                  />
                </div>
                <div>
                  <input
                    className={styles.uploadButton}
                    disabled={uploadDisable}
                    type="submit"
                    value="Upload"
                  />
                </div>
              </div>
            </form>
          </div>
        ) : (
          <DisplayVideo
            videoDisplayRef={videoDisplayRef}
            timeValue={videoTime}
            onTimeUpdate={setTimelinePlayTime}
          />
        )}
        <div
          className={
            uploadParams.file_id
              ? "row " + styles.exportContainer
              : styles.displayNone
          }
        >
          <ExportData filename={state?.filename} />
        </div>
        <div className={styles.refreshButtonContainer}>
          <button
            className={styles.refresh}
            onClick={() => refreshData(uploadParams.file_id)}
          >
            Refresh
          </button>
        </div>
      </div>
      <div className={styles.timelineRow}>
        <VideoTimeline
          timeValue={timelineTime}
          durationAmount={videoDuration}
          onTimeUpdate={setVideoPlayTime}
          onPlayPause={handlePlayPause}
          onMute={handleMute}
        />
      </div>
    </div>
  );
}

export default MainEditor;
