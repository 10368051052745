import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AppDataContext from "../../../contexts/AppDataContext";
import MobileEditPanel from "../MobileEditPanel";
import MobileNavbar from "../MobileNavbar";
import MobileSidePanel from "../MobileSidePanel";
import Timeline from "../Timeline";
import SubtitleArea from "../SubtitleArea";
import VideoPlayer from "../VideoPlayer";
import styles from "./MobileEditor.module.css";

function MobileEditor() {
  const { state } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { videoFileURL, subtitleSrc, handleSubtitleTrack, Subtitle } =
    useContext(AppDataContext);

  useEffect(() => {
    handleSubtitleTrack(); // eslint-disable-next-line
  }, [Subtitle]);

  const slideClass = () => {
    setIsOpen(true);
  };

  const slideOut = () => {
    setIsOpen(false);
  };

  const sidePanelClass = isOpen
    ? styles.sidePanelContainer + " " + styles.openMenu
    : styles.sidePanelContainer;

  const mainClass = isOpen
    ? styles.mainContainer + " " + styles.noScroll
    : styles.mainContainer;

  return (
    <div className={mainClass}>
      <div className={sidePanelClass}>
        <MobileSidePanel />
      </div>
      <MobileNavbar setClass={slideClass} />
      <div className={styles.innerContainer} onClick={slideOut}>
        <VideoPlayer videoUrl={videoFileURL} subtitle={subtitleSrc} />
        <div className={styles.subtitleAreaContainer}>
          <SubtitleArea />
        </div>
        <MobileEditPanel filename={state?.filename} />
        <div className={styles.timelineContainer}>
          <Timeline />
        </div>
      </div>
    </div>
  );
}

export default MobileEditor;
