import { useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import AppDataContext from "../../../contexts/AppDataContext";
import logoImage from "../../../data/images/FilmEditor-white.png";
import styles from "./Login.module.css";

function Login() {
  const { setIsAuthenticated, baseURL } = useContext(AppDataContext);
  const [loginErr, setLoginErr] = useState({
    condition: false,
    title: "",
    message: "",
  });
  const [passwordText, setPasswordText] = useState("");
  const [fieldType, setFieldType] = useState("password");
  const [icon, setIcon] = useState(faEyeSlash);
  const navigate = useNavigate();
  const loginURL = `${baseURL}/adminstrator/login/`;
  const setCsrfURL = `${baseURL}/adminstrator/setcsrf/`;

  const handleSubmit = (event) => {
    event.preventDefault();
    const inputUsername = event.target.elements.username.value;
    const inputPassword = event.target.elements.password.value;
    if (inputUsername && inputPassword) {
      axios
        .get(setCsrfURL)
        .then((res) => console.log("Csrf:", res))
        .catch((err) => console.log("Csrf:", err));
      setTimeout(() => {
        axios
          .post(
            loginURL,
            {
              username: inputUsername,
              password: inputPassword,
            },
            { withCredentials: true }
          )
          .then(() => {
            setIsAuthenticated(true);
            navigate("/", { replace: true });
          })
          .catch((err) => {
            setLoginErr({
              condition: true,
              title: err.response.statusText,
              message:
                err.response.status === 403
                  ? err.message
                  : err.response.data.non_field_errors[0],
            });
          });
      }, 1000);
    } else {
      setLoginErr({
        condition: true,
        title: "Login Error",
        message: "Username or Password is not provided",
      });
    }
  };

  const handleClose = () => {
    setLoginErr({ condition: false, title: "", message: "" });
  };

  const handleToggle = () => {
    if (fieldType === "password") {
      setIcon(faEye);
      setFieldType("text");
    } else {
      setIcon(faEyeSlash);
      setFieldType("password");
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div
            className={
              "card col-xl-4 col-lg-4 col-md-6 col-sm-8 col-10 " +
              styles.cardContainer
            }
          >
            <div className={styles.cardBody}>
              <div className={"card-title " + styles.logoContainer}>
                <img src={logoImage} alt="LOGO" className={styles.logo} />
              </div>
              <form onSubmit={(event) => handleSubmit(event)}>
                <div className={"form-group " + styles.form}>
                  <input
                    className={
                      "form-control " +
                      styles.formControl +
                      " " +
                      styles.inputPlaceholder
                    }
                    id="formInput#text"
                    type="text"
                    name="username"
                    autoComplete="on"
                    placeholder="Username"
                  />
                </div>
                <div className={"form-group " + styles.form}>
                  <input
                    className={
                      "form-control " +
                      styles.formControl +
                      " " +
                      styles.inputPlaceholder
                    }
                    id="formInput#password"
                    type={fieldType}
                    value={passwordText}
                    onChange={(event) => setPasswordText(event.target.value)}
                    name="password"
                    placeholder="Password"
                  />
                  <FontAwesomeIcon
                    icon={icon}
                    onClick={handleToggle}
                    className={styles.passwordIcon}
                  />
                </div>
                <div
                  className={
                    "row justify-content-between " + styles.rememberForgetRow
                  }
                >
                  <div
                    className={
                      "col-5 " +
                      styles.rememberForgetContainer +
                      " " +
                      styles.checkboxDirection
                    }
                  >
                    <label htmlFor="rememberMe" className={styles.robotLabel}>
                      Remember me
                    </label>
                    <input
                      type="checkbox"
                      name="rememberMe"
                      id="rememberMe"
                      className={styles.checkboxDirection}
                    />
                  </div>
                  <div className={"col-5 " + styles.rememberForgetContainer}>
                    <a href="/" className={styles.forgetLink}>
                      Forget Password?
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div
                    className={
                      styles.RobotContainer + " " + styles.checkboxDirection
                    }
                  >
                    <label htmlFor="notRobot" className={styles.robotLabel}>
                      I'm not Robot
                    </label>
                    <input
                      type="checkbox"
                      name="notRobot"
                      id="notRobot"
                      className={styles.robotCheckbox}
                    />
                  </div>
                </div>
                <div className={"row form-group " + styles.buttonContainer}>
                  <input
                    className={
                      "btn btn-primary " +
                      styles.button +
                      " " +
                      styles.buttonColor
                    }
                    type="submit"
                    value="Log In"
                  />
                </div>
              </form>
              <hr />
              <div className={styles.alternative}>
                <div className={"row " + styles.question}>
                  <p>Don’t have an Account?</p>
                </div>
                <div className={"row form-group " + styles.buttonContainer}>
                  <button
                    className={"btn btn-warning " + styles.button}
                    onClick={() => navigate("/signup")}
                  >
                    Signup
                  </button>
                </div>
              </div>
              <div className={styles.homepageLink}>
                <Link to="/">Homepage</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={loginErr.condition} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{loginErr.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{loginErr.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Login;
