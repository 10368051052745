import { MdMergeType } from "react-icons/md";
import styles from "./InvertVerticalMerge.module.css";
import { useContext } from "react";
import AppDataContext from "../../../contexts/AppDataContext";

function InvertVerticalMerge() {
  const { handleMerge, panelItem, retreiveIndex, retreiveLength } =
    useContext(AppDataContext);
  return (
    <button
      className={styles.button}
      disabled={
        !panelItem.id || retreiveIndex(panelItem) === retreiveLength() - 1
      }
      onClick={() => handleMerge(panelItem, "front")}
    >
      Merge&#160;
      <MdMergeType className={styles.mergeIcon} />
    </button>
  );
}

export default InvertVerticalMerge;
