import { useContext } from "react";
import { MdMergeType } from "react-icons/md";
import styles from "./VerticalMerge.module.css";
import AppDataContext from "../../../contexts/AppDataContext";

function VerticalMerge() {
  const { handleMerge, panelItem } = useContext(AppDataContext);
  return (
    <button
      className={styles.button}
      disabled={!panelItem.id || !panelItem?.previous_id}
      onClick={() => handleMerge(panelItem, "back")}
    >
      Merge&#160;
      <MdMergeType className={styles.mergeIcon} />
    </button>
  );
}

export default VerticalMerge;
