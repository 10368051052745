import { MdMergeType } from "react-icons/md";
import styles from "./InvertMerge.module.css";

function InvertMerge({ item, handle }) {
  return (
    <div className={styles.mainContainer}>
      <button
        className={styles.button}
        // disabled={isLast}
        onClick={() => handle(item, "front")}
      >
        Merge&#160;&#160;&#160;&#160;&#160;&#160;
        <MdMergeType className={styles.mergeIcon} />
      </button>
    </div>
  );
}

export default InvertMerge;
