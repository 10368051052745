import { FaPlay } from "react-icons/fa";
import { HiSpeakerWave } from "react-icons/hi2";
import { MdOutlineForward10, MdOutlineReplay10 } from "react-icons/md";
import styles from "./Timeline.module.css";

function Timeline() {
  return (
    <div className={styles.mainContainer}>
      <input
        type="range"
        name="timeLine"
        id="timeLine"
        className={styles.input}
        step={0.1}
      />
      <button disabled={true} className={styles.backForButton}>
        <MdOutlineReplay10 className={styles.icons} />
      </button>
      <button disabled={true} className={styles.playButton}>
        <FaPlay className={styles.icons} />
      </button>
      <button disabled={true} className={styles.backForButton}>
        <MdOutlineForward10 className={styles.icons} />
      </button>
      <button disabled={true} className={styles.backForButton}>
        <HiSpeakerWave className={styles.icons} />
      </button>
    </div>
  );
}

export default Timeline;
