import { faCrown, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { HiCurrencyDollar } from "react-icons/hi2";
import { LuLogOut } from "react-icons/lu";
import { MdEdit, MdLogin, MdMovie, MdSettings, MdWindow } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import AppDataContext from "../../../contexts/AppDataContext";
import logoImage from "../../../data/images/FilmEditor-fav-full-white.png";
import MobileDashboard from "../../MobileView/MobileDashboard";
import styles from "./Dashboard.module.css";

function Dashboard() {
  const originOptions = ["English", "Farsi", "French", "Deutsch"];
  const translateOptions = ["", "English", "Farsi", "French", "Deutsch"];
  const videoRef = useRef(null);
  const [fileInputLabel, setFileInputLabel] = useState(
    "Upload your video here"
  );
  const [activation, setActivation] = useState({
    dashboard: true,
    projects: false,
    upgrade: false,
    credit: false,
    settings: false,
  });
  const [languageData, setLanguageData] = useState({
    original_language: "en",
    translate_language: "fa",
  });
  const [buttonDisable, setButtonDisable] = useState(true);
  const [videoThumbnails, setVideoThumbnails] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [popupShow, setPopupShow] = useState(false);
  const navigate = useNavigate();
  const {
    isAuth,
    logout,
    handleUpload,
    handleSubtitleTrack,
    baseURL,
    setIsLoadingValue,
    uploadParams,
    setUploadParamValues,
  } = useContext(AppDataContext);
  const dashboardEndpoint = "/video/dashboard/";
  const uploadFileEndpoint = "/transcript/upload_file/";

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
    return () => {
      setIsMobile(false);
    };
  }, []);

  useEffect(() => {
    if (uploadParams.url && videoRef.current?.files) {
      axios
        .put(uploadParams.url, videoRef.current.files[0], {
          redirect: "follow",
          headers: { "Content-Type": "" },
        })
        .then(() => {
          navigate(`/${uploadParams.file_id}`, {
            state: {
              filename: videoRef.current.value.split("\\")[2],
            },
          });
        })
        .catch((err) => console.log("Put to MinIo:", err));
    } // eslint-disable-next-line
  }, [uploadParams]);

  const inputFileIsValid = (file) => {
    if (file.current.files[0].type === "video/mp4") {
      return true;
    } else {
      return false;
    }
  };

  const handleuploadClick = () => {
    setIsLoadingValue({
      condition: true,
      message: "Uploading your video file ... ",
    });
    setButtonDisable(true);
    handleUpload(videoRef);
    if (inputFileIsValid(videoRef)) {
      handleSubtitleTrack();
      axios
        .post(
          `${baseURL}${uploadFileEndpoint}`,
          { file_name: videoRef.current.value.split("\\")[2], ...languageData },
          { withCredentials: true }
        )
        .then((res) => setUploadParamValues(res.data))
        .catch((err) => {
          console.log("Post Filename", err);
          navigate("/login");
        });
    } else {
      setFileInputLabel('**You have to upload a "video.mp4" file **');
    }
  };

  useEffect(() => {
    axios
      .get(`${baseURL}${dashboardEndpoint}`, { withCredentials: true })
      .then((res) => setVideoThumbnails(res.data.videos.slice(0, 3)))
      .catch((err) => console.log(err)); // eslint-disable-next-line
  }, []);

  const handleActivation = (section) => {
    let activeObject = { ...activation };
    for (let key in activation) {
      if (key === section) {
        activeObject[key] = true;
      } else {
        activeObject[key] = false;
      }
    }
    setActivation(activeObject);
  };

  const handleFileInputChange = (event) => {
    setButtonDisable(false);
    setFileInputLabel(event.target.value.split("\\")[2]);
  };

  useEffect(() => {
    if (languageData.original_language === languageData.translate_language) {
      setLanguageData({ ...languageData, translate_language: "" });
    }
  }, [languageData]);

  const handleLanguageSelect = (event, property) => {
    const languageCode = event.target.value.toLowerCase().substr(0, 2);
    setLanguageData({ ...languageData, [property]: languageCode });
  };

  const newProjectPop = () => {
    setPopupShow(true);
  };

  const handleClose = () => {
    videoRef.current = null;
    setFileInputLabel("Upload your video here");
    setButtonDisable(true);
    setPopupShow(false);
  };

  if (isMobile) return <MobileDashboard videoThumbnails={videoThumbnails} />;

  return (
    <div className={styles.mainContainer}>
      <div className={"row " + styles.mainRow}>
        <div className={"col-2 " + styles.mainColumn}>
          <div className={styles.menuColumn}>
            <div className={styles.logoContainer} onClick={() => navigate("/")}>
              <img src={logoImage} alt="LOGO" className={styles.logo} />
            </div>
            <ul className={styles.menuList}>
              <li
                className={
                  activation.dashboard
                    ? styles.menuItem + " " + styles.activeItem
                    : styles.menuItem
                }
                onClick={() => handleActivation("dashboard")}
              >
                <div className={styles.itemText}>
                  <MdWindow className={styles.iconsColor} />
                  &#160;&#160;Dashboard
                </div>
              </li>
              <li
                className={
                  activation.projects
                    ? styles.menuItem + " " + styles.activeItem
                    : styles.menuItem
                }
                onClick={() => handleActivation("projects")}
              >
                <div className={styles.itemText}>
                  <MdMovie className={styles.iconsColor} />
                  &#160;&#160;Projects
                </div>
              </li>
              <li
                className={
                  activation.upgrade
                    ? styles.menuItem + " " + styles.activeItem
                    : styles.menuItem
                }
                onClick={() => handleActivation("upgrade")}
              >
                <div className={styles.itemText}>
                  <FontAwesomeIcon
                    icon={faCrown}
                    className={styles.iconsColor}
                    size="sm"
                  />
                  &#160;&#160;Upgrade
                </div>
              </li>
              <li
                className={
                  activation.credit
                    ? styles.menuItem + " " + styles.activeItem
                    : styles.menuItem
                }
                onClick={() => handleActivation("credit")}
              >
                <div className={styles.itemText}>
                  <HiCurrencyDollar className={styles.iconsColor} />
                  &#160;&#160;Credit & Billing
                </div>
              </li>
              <li
                className={
                  activation.settings
                    ? styles.menuItem + " " + styles.activeItem
                    : styles.menuItem
                }
                onClick={() => handleActivation("settings")}
              >
                <div className={styles.itemText}>
                  <MdSettings className={styles.iconsColor} />
                  &#160;&#160;Settings
                </div>
              </li>
            </ul>
            <div className={styles.logoutContainer}>
              {isAuth ? (
                <button
                  className={styles.menuItem + " " + styles.logoutButton}
                  onClick={() => {
                    logout();
                    navigate("/login");
                  }}
                >
                  <LuLogOut />
                  &#160;&#160;Logout
                </button>
              ) : (
                <button
                  className={styles.menuItem + " " + styles.logoutButton}
                  onClick={() => navigate("/login")}
                >
                  <MdLogin />
                  &#160;&#160;Login
                </button>
              )}
            </div>
          </div>
        </div>
        <div className={"col-10 " + styles.mainColumn}>
          <div className={styles.contentArea}>
            <div className="row">
              <p className={styles.titleText}>Recent Projects</p>
              <hr className={styles.hrLine} />
            </div>
            <div className={"row " + styles.projectRow}>
              <div className={styles.projectContainer}>
                <div className={styles.newProject} onClick={newProjectPop}>
                  <div className={styles.iconContainer}>
                    <div className={styles.partsWrapper}>
                      <div className={styles.iconWrapper}>
                        <FontAwesomeIcon
                          icon={faPlus}
                          className={styles.newProjectIcon}
                        />
                      </div>
                      <div className={styles.textWrapper}>
                        <p className={styles.addText}>Add new Project</p>
                      </div>
                    </div>
                  </div>
                </div>
                {videoThumbnails.map((item) => (
                  <div className={styles.projectWrapper} key={item.file_id}>
                    <img
                      src={item.thumbnail_dl_url}
                      alt={item.filename}
                      className={styles.videoThumb}
                    />
                    <div
                      className={styles.middle}
                      onClick={() =>
                        navigate(`/${item.file_id}`, {
                          state: { filename: item.filename.slice(0, -4) },
                        })
                      }
                    >
                      <MdEdit className={styles.editIcon} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="row">
              <p className={styles.titleText}>Account Status</p>
              <hr className={styles.hrLine} />
            </div>
            <div className="row">
              <div className={styles.middleDiv}></div>
            </div>
            <div className="row">
              <p className={styles.titleText}>Tutorials</p>
              <hr className={styles.hrLine} />
            </div>
            <div className={"row " + styles.videoRow}>
              <div className="col-4">
                <div className={styles.bottomDiv}></div>
              </div>
              <div className="col-4">
                <div className={styles.bottomDiv}></div>
              </div>
              <div className="col-4">
                <div className={styles.bottomDiv}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={popupShow} onHide={handleClose} centered>
        <Modal.Body>
          <div className={styles.popupBodyContainer}>
            <div className={styles.videoDropContainer}>
              <form
                id="newProjectForm"
                className={styles.form}
                encType="multipart/form-data"
              >
                <div
                  className={
                    "row justify-content-center " + styles.formRowContainer
                  }
                >
                  <div className={styles.inputFileContainer}>
                    <label htmlFor="uploadVideo" className={styles.inputLabel}>
                      {fileInputLabel}
                    </label>
                    <input
                      className={styles.inputFile}
                      type="file"
                      name="uploadVideo"
                      ref={videoRef}
                      accept=".mp4"
                      onChange={(event) => handleFileInputChange(event)}
                      id="uploadVideo"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className={styles.videoFooterContainer}>
              <div>
                <label htmlFor="Origins" className={styles.selectLabel}>
                  Origin:
                </label>
                <select
                  name="Origins"
                  id="Origins"
                  className={styles.SelectionPop}
                  onChange={(event) =>
                    handleLanguageSelect(event, "original_language")
                  }
                >
                  {originOptions.map((option, index) => (
                    <option key={index}>{option}</option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="Translates" className={styles.selectLabel}>
                  Translate to:
                </label>
                <select
                  name="Translates"
                  id="Translates"
                  className={styles.SelectionPop}
                  defaultValue={"Farsi"}
                  onChange={(event) =>
                    handleLanguageSelect(event, "translate_language")
                  }
                >
                  {translateOptions.map((option, index) => (
                    <option key={index}>{option}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className={styles.footerContainer}>
            <button
              className={styles.popupButton + " " + styles.popupCancel}
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              disabled={buttonDisable}
              type="submit"
              className={styles.popupButton + " " + styles.popupSatrt}
              onClick={handleuploadClick}
            >
              Start
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Dashboard;
