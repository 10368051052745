import { useContext, useRef, useState } from "react";
import AppDataContext from "../../contexts/AppDataContext";
import styles from "./UploadForm.module.css";

function UploadForm() {
  const videoRef = useRef(null);
  const [fileInputLabel, setFileInputLabel] = useState(
    "You need to upload your video again! Click here to select"
  );
  const { handleUpload } = useContext(AppDataContext);

  const handleFileInputChange = (event) => {
    setFileInputLabel(event.target.value.split("\\")[2]);
  };
  return (
    <div className={styles.mainContainer}>
      <form
        className={styles.form}
        onSubmit={(event) => {
          event.preventDefault();
          handleUpload(videoRef);
        }}
        encType="multipart/form-data"
      >
        <div>
          <div className={styles.inputContainer}>
            <label className={styles.inputLabel} htmlFor="uploadVideo">
              {fileInputLabel}
            </label>
            <input
              className={styles.inputFile}
              type="file"
              name="uploadVideo"
              ref={videoRef}
              accept=".mp4"
              onChange={(event) => handleFileInputChange(event)}
              id="uploadVideo"
            />
          </div>
          <div>
            <input
              className={styles.uploadButton}
              // disabled={uploadDisable}
              type="submit"
              value="Upload"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default UploadForm;
