import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MdOutlineTranslate } from "react-icons/md";
import styles from "./BoldItem.module.css";
import AppDataContext from "../../contexts/AppDataContext";
import axios from "axios";

function BoldItem({ item }) {
  const translateEndpoint = "/transcript/translate/";

  const [translatedTranscript, setTranslatedTranscript] = useState(
    item.translated_transcript
  );
  const { handleSubtitleChange, baseURL, handleUpdate } =
    useContext(AppDataContext);

  useEffect(() => {
    setTranslatedTranscript(item.translated_transcript);
  }, [item]);

  const handleChange = (event, property) => {
    handleSubtitleChange({ ...item, [property]: event.target.value });
    if (property === "translated_transcript") {
      setTranslatedTranscript(event.target.value);
    }
  };

  const handleTranslation = (event, translateItem) => {
    event.preventDefault();
    axios
      .post(
        `${baseURL}${translateEndpoint}`,
        { text: event.currentTarget.original_transcript.value },
        { withCredentials: true }
      )
      .then((res) => {
        handleSubtitleChange({
          ...translateItem,
          translated_transcript: res.data.translated_text,
        });
        handleUpdate(
          translateItem.id,
          "translated_transcript",
          res.data.translated_text
        );
        setTranslatedTranscript(res.data.translated_text);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={styles.mainContainer}>
      <form
        onBlur={(event) =>
          handleUpdate(item.id, event.target.name, event.target.value)
        }
        onSubmit={(event) => handleTranslation(event, item)}
      >
        <div className={styles.rowContainer}>
          <div className={styles.buttonContainer}>
            <button className={styles.button} type="submit">
              <MdOutlineTranslate className={styles.buttonIcon} />
            </button>
          </div>
          <div className="row justify-content-center">
            <div className="col-6">
              <textarea
                className={"form-control " + styles.transcript}
                value={item.original_transcript}
                rows={4}
                name="original_transcript"
                onChange={(event) => handleChange(event, "original_transcript")}
              />
            </div>
            <div className="col-6">
              <textarea
                className={
                  "form-control " +
                  styles.transcript +
                  " " +
                  styles.farsiDirection
                }
                value={translatedTranscript}
                rows={4}
                name="translated_transcript"
                onChange={(event) =>
                  handleChange(event, "translated_transcript")
                }
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

BoldItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    original_transcript: PropTypes.string || null,
    translated_transcript: PropTypes.string || null,
  }),
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
};

export default BoldItem;
