import styles from "./MobileNavbar.module.css";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../../data/images/FilmEditor-fullwhite.png";

function MobileNavbar({ setClass }) {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.iconContainer}>
        <FontAwesomeIcon
          icon={faBars}
          onClick={setClass}
          className={styles.icon}
        />
      </div>
      <div className={styles.logoContainer}>
        <img src={logo} alt="LOGO" className={styles.logo} />
      </div>
    </div>
  );
}

export default MobileNavbar;
