import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppDataProvider } from "./contexts/AppDataContext";

import MainEditor from "./components/MainEditor";
import Dashboard from "./components/pages/Dashboard";
import Login from "./components/pages/Login";
import Signup from "./components/pages/Signup";
import Status404 from "./components/pages/Status404";

function App() {
  return (
    <BrowserRouter>
      <AppDataProvider>
        <Routes>
          <Route exact path="/" element={<Dashboard />}></Route>
          <Route path="/:file_id" element={<MainEditor />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/status-not-found" element={<Status404 />}></Route>
        </Routes>
      </AppDataProvider>
    </BrowserRouter>
  );
}

export default App;
