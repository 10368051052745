import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import styles from "./Insert.module.css";

function Insert({ item, handle }) {
  return (
    <div className={styles.mainContainer}>
      <button
        className={styles.button}
        disabled={item.id === ""}
        onClick={() => handle(item)}
      >
        <FontAwesomeIcon icon={faPlus} />
        &#160;&#160;&#160;Insert
      </button>
    </div>
  );
}

export default Insert;
