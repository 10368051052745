import { faCrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { HiCurrencyDollar } from "react-icons/hi2";
import { LuLogOut } from "react-icons/lu";
import { MdLogin, MdMovie, MdSettings, MdWindow } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import AppDataContext from "../../../contexts/AppDataContext";
import logoImage from "../../../data/images/FilmEditor-fav.png";
import styles from ".//MobileSidePanel.module.css";

function MobileSidePanel() {
  const [activation, setActivation] = useState({
    dashboard: true,
    projects: false,
    upgrade: false,
    credit: false,
    settings: false,
  });
  const navigate = useNavigate();
  const { logout, isAuth } = useContext(AppDataContext);
  const handleActivation = (section) => {
    let activeObject = { ...activation };
    for (let key in activation) {
      if (key === section) {
        activeObject[key] = true;
      } else {
        activeObject[key] = false;
      }
    }
    setActivation(activeObject);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.logoContainer} onClick={() => navigate("/")}>
        <img src={logoImage} alt="LOGO" className={styles.logo} />
      </div>
      <ul className={styles.menuList}>
        <li
          className={
            activation.dashboard
              ? styles.menuItem + " " + styles.activeItem
              : styles.menuItem
          }
          onClick={() => handleActivation("dashboard")}
        >
          <div className={styles.itemText}>
            <MdWindow />
            &#160;&#160;Dashboard
          </div>
        </li>
        <li
          className={
            activation.projects
              ? styles.menuItem + " " + styles.activeItem
              : styles.menuItem
          }
          onClick={() => handleActivation("projects")}
        >
          <div className={styles.itemText}>
            <MdMovie />
            &#160;&#160;Projects
          </div>
        </li>
        <li
          className={
            activation.upgrade
              ? styles.menuItem + " " + styles.activeItem
              : styles.menuItem
          }
          onClick={() => handleActivation("upgrade")}
        >
          <div className={styles.itemText}>
            <FontAwesomeIcon icon={faCrown} size="sm" />
            &#160;&#160;Upgrade
          </div>
        </li>
        <li
          className={
            activation.credit
              ? styles.menuItem + " " + styles.activeItem
              : styles.menuItem
          }
          onClick={() => handleActivation("credit")}
        >
          <div className={styles.itemText}>
            <HiCurrencyDollar />
            &#160;&#160;Credit & Billing
          </div>
        </li>
        <li
          className={
            activation.settings
              ? styles.menuItem + " " + styles.activeItem
              : styles.menuItem
          }
          onClick={() => handleActivation("settings")}
        >
          <div className={styles.itemText}>
            <MdSettings />
            &#160;&#160;Settings
          </div>
        </li>
      </ul>
      <div className={styles.logoutContainer}>
        {isAuth ? (
          <button
            className={styles.menuItem + " " + styles.logoutButton}
            onClick={() => {
              logout();
              navigate("/login");
            }}
          >
            <LuLogOut />
            &#160;&#160;Logout
          </button>
        ) : (
          <button
            className={styles.menuItem + " " + styles.logoutButton}
            onClick={() => navigate("/login")}
          >
            <MdLogin />
            &#160;&#160;Login
          </button>
        )}
      </div>
    </div>
  );
}

export default MobileSidePanel;
