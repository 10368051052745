import { useNavigate } from "react-router-dom";
import styles from "./Status404.module.css";
import logoImage from "../../../data/images/FilmEditor-main.png";
import mainImage from "../../../data/images/404.png";
import { useEffect, useState } from "react";
import Mobile404 from "./Mobile404";

function Status404() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
    return () => {
      setIsMobile(false);
    };
  }, []);

  if (isMobile) return <Mobile404 />;

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}>
        <img src={logoImage} alt="Page Logo" className={styles.logoImage} />
      </div>
      <div className={styles.bodyContainer}>
        <img
          src={mainImage}
          alt="404 Page not Found"
          className={styles.bodyImage}
        />
      </div>
      <div className={styles.footerContainer}>
        <button className={styles.homepageButton} onClick={() => navigate("/")}>
          Home Page
        </button>
      </div>
    </div>
  );
}

export default Status404;
