import { useContext } from "react";
import styles from "./SingleMobileFocused.module.css";
import AppDataContext from "../../../contexts/AppDataContext";

function SingleMobileFocused({ item }) {
  const { handleUpdate, handleSubtitleChange } = useContext(AppDataContext);
  const handleChange = (event, property) => {
    handleSubtitleChange({ ...item, [property]: event.target.value });
  };
  return (
    <div className={styles.mainContainer}>
      <form
        onBlur={(event) =>
          handleUpdate(item.id, event.target.name, event.target.value)
        }
      >
        <textarea
          className={"form-control " + styles.transcript}
          value={item.original_transcript}
          rows={3}
          name="original_transcript"
          onChange={(event) => handleChange(event, "original_transcript")}
        />
      </form>
    </div>
  );
}

export default SingleMobileFocused;
