import styles from "./PureTime.module.css";

function PureTime({ time, property }) {
  return (
    <div className={styles.mainContainer}>
      <input
        className={
          property === "start_time"
            ? styles.input
            : styles.input + " " + styles.blackBg
        }
        type="text"
        name={property}
        value={time}
        readOnly
      />
    </div>
  );
}

export default PureTime;
