import { useContext, useEffect, useState } from "react";
import Delete from "../../EditPanelComponents/Delete";
import Export from "../../EditPanelComponents/Export";
import Insert from "../../EditPanelComponents/Insert";
import InvertMerge from "../../EditPanelComponents/InvertMerge";
import Merge from "../../EditPanelComponents/Merge";
import Split from "../../EditPanelComponents/Split";
import Time from "../../EditPanelComponents/Time";
import styles from "./MobileEditPanel.module.css";
import AppDataContext from "../../../contexts/AppDataContext";
import ExportMessage from "../../EditPanelComponents/ExportMessage";
import axios from "axios";

function MobileEditPanel({ filename }) {
  const {
    panelItem,
    handleDeletion,
    handleMerge,
    handleInsertion,
    handleSplition,
    uploadParams,
    baseURL,
  } = useContext(AppDataContext);

  const exportPostVideoUrl = "/video/video_burn/";
  const exportGetVideoUrl = "/video/video_burn";
  const downloadSrtUrl = "/transcript/dl_srt";
  const [exportLink, setExportLink] = useState({ condition: false, url: "" });
  const [isPreparing, setIsPreparing] = useState(false);
  const [mp4ButtonStyle, setMp4ButtonStyle] = useState(
    styles.export + " " + styles.mp4Button
  );

  let intervalId;
  useEffect(() => {
    if (exportLink.condition) {
      clearInterval(intervalId);
    } // eslint-disable-next-line
  }, [intervalId]);

  const getRequest = () => {
    axios
      .get(`${baseURL}${exportGetVideoUrl}?file_id=${uploadParams.file_id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setExportLink({ condition: true, url: res.data.url });
        setIsPreparing(false);
        setMp4ButtonStyle(mp4ButtonStyle + " " + styles.greenBackground);
      })
      .catch((err) => console.log(err.response.data.message));
  };

  const exportMp4 = () => {
    if (exportLink.condition) {
      const link = document.createElement("a");
      link.href = exportLink.url;
      link.setAttribute("download", filename ? filename : uploadParams.file_id);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      axios
        .post(
          `${baseURL}${exportPostVideoUrl}`,
          { file_id: uploadParams.file_id },
          { withCredentials: true }
        )
        .then(() => {
          setExportLink({ condition: false, url: "" });
          setIsPreparing(true);
          setMp4ButtonStyle(styles.export + " " + styles.mp4Button);
        })
        .catch((err) => console.log(err));
      intervalId = setInterval(getRequest, 5000);
    }
  };

  const exportSrt = () => {
    axios
      .get(`${baseURL}${downloadSrtUrl}?file_id=${uploadParams.file_id}`, {
        withCredentials: true,
      })
      .then((res) => {
        const link = document.createElement("a");
        link.target = "_blank";
        link.href = res.data.url;
        link.setAttribute("download", `${filename}.srt`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.merge}>
        <Merge item={panelItem} handle={handleMerge} />
        <InvertMerge item={panelItem} handle={handleMerge} />
      </div>
      <div className={styles.isdteContainer}>
        <div className={styles.isd}>
          <div className={styles.isdContainer}>
            <Insert item={panelItem} handle={handleInsertion} />
          </div>
          <div className={styles.isdContainer}>
            <Split item={panelItem} handle={handleSplition} />
          </div>
          <div className={styles.isdContainer}>
            <Delete item={panelItem} handle={handleDeletion} />
          </div>
        </div>
        <div className={styles.time}>
          <div className={styles.timeContainer + " " + styles.marginBottom}>
            <Time property={"start_time"} />
          </div>
          <div className={styles.timeContainer}>
            <Time property={"end_time"} />
          </div>
        </div>
        <div className={styles.export}>
          <Export
            handleExportMp4={exportMp4}
            handleExportSrt={exportSrt}
            isReady={exportLink.condition}
          />
        </div>
      </div>
      <div className={styles.exportMassageContainer}>
        <ExportMessage
          isPreparing={isPreparing}
          isReady={exportLink.condition}
        />
      </div>
    </div>
  );
}

export default MobileEditPanel;
