import { faDownload, faFilm } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MdOutlineSubtitles } from "react-icons/md";
import styles from "./Export.module.css";
import { useEffect, useState } from "react";

function Export({ handleExportMp4, handleExportSrt, isReady }) {
  const [mp4ButtonStyle, setMp4ButtonStyle] = useState(
    styles.export + " " + styles.mp4Button
  );
  useEffect(() => {
    if (isReady) {
      setMp4ButtonStyle(
        styles.export + " " + styles.mp4Button + " " + styles.greenBackground
      );
    } else {
      setMp4ButtonStyle(styles.export + " " + styles.mp4Button);
    }
  }, [isReady]);
  return (
    <div className={styles.mainContainer}>
      <div className={styles.rowContain}>
        <div className={styles.export + " " + styles.header}>
          <FontAwesomeIcon icon={faDownload} />
          &#160;&#160;Export
        </div>
        <div className={styles.hrContainer}>
          <hr className={styles.hr} />
        </div>
      </div>
      <div className={styles.rowContain}>
        <button className={mp4ButtonStyle} onClick={handleExportMp4}>
          <FontAwesomeIcon icon={faFilm} />
          &#160;&#160;&#160;&#160;&#160;&#160;MP4
        </button>
      </div>
      <div className={styles.rowContain}>
        <button
          className={styles.export + " " + styles.srtButton}
          onClick={() => handleExportSrt()}
        >
          <MdOutlineSubtitles />
          &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;SRT
        </button>
      </div>
    </div>
  );
}

export default Export;
