import { faScissors } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Split.module.css";

function Split({ item, handle }) {
  return (
    <div className={styles.mainContainer}>
      <button
        className={styles.button + " " + styles.blackBackground}
        disabled={item.id === ""}
        onClick={() => handle(item)}
      >
        <FontAwesomeIcon icon={faScissors} />
        &#160;&#160;&#160;Split
      </button>
    </div>
  );
}

export default Split;
