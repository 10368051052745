import { useContext } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Signup.module.css";
import AppDataContext from "../../../contexts/AppDataContext";
import logoImage from "../../../data/images/FilmEditor-main.png";

function Signup() {
  const { baseURL } = useContext(AppDataContext);
  const signupURL = `${baseURL}/adminstrator/signup/`;
  const navigate = useNavigate();
  function isPasswordsMatch(password1, password2) {
    if (password1 === password2) {
      return true;
    } else {
      return false;
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      isPasswordsMatch(
        event.target.password1.value,
        event.target.password2.value
      )
    ) {
      const credentialsObject = {
        username: event.target.username.value,
        email: event.target.email.value,
        password: event.target.password1.value,
      };
      axios
        .post(signupURL, credentialsObject, { withCredentials: true })
        .then(() => {
          navigate("/login", { replace: true });
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <div className={styles.mainContainer}>
      <div className={"container-fluid " + styles.innerContainer}>
        <div className="row justify-content-center">
          <div
            className={
              "card col-xl-4 col-lg-4 col-md-6 col-sm-8 col-10 " +
              styles.cardContainer
            }
          >
            <div className={styles.cardBody}>
              <div className={"card-title " + styles.logoContainer}>
                <img src={logoImage} alt="LOGO" className={styles.logo} />
              </div>
              <form onSubmit={(event) => handleSubmit(event)}>
                <div className={"form-group " + styles.form}>
                  <input
                    className={
                      "form-control " +
                      styles.formControl +
                      " " +
                      styles.inputPlaceholder
                    }
                    id="formInput#username"
                    type="text"
                    name="username"
                    placeholder="Enter your username..."
                  />
                </div>
                <div className={"form-group " + styles.form}>
                  <input
                    className={
                      "form-control " +
                      styles.formControl +
                      " " +
                      styles.inputPlaceholder
                    }
                    id="formInput#email"
                    type="email"
                    name="email"
                    placeholder="Enter your Email..."
                  />
                </div>
                <div className={"form-group " + styles.form}>
                  <input
                    className={
                      "form-control " +
                      styles.formControl +
                      " " +
                      styles.inputPlaceholder
                    }
                    id="formInput#password"
                    type="password"
                    name="password1"
                    placeholder="••••••••"
                  />
                </div>
                <div className={"form-group " + styles.form}>
                  <input
                    className={
                      "form-control " +
                      styles.formControl +
                      " " +
                      styles.inputPlaceholder
                    }
                    id="formInput#re-password"
                    type="password"
                    name="password2"
                    placeholder="••••••••"
                  />
                </div>
                <div
                  className={
                    "row justify-content-between " + styles.serviceTermRow
                  }
                >
                  <div
                    className={
                      styles.serviceTermContainer +
                      " " +
                      styles.checkboxDirection
                    }
                  >
                    <label htmlFor="rememberMe" className={styles.robotLabel}>
                      I agree to the terms and conditions
                    </label>
                    <input
                      type="checkbox"
                      name="agreeTerms"
                      id="agreeTerms"
                      className={styles.checkboxDirection}
                    />
                  </div>
                </div>
                <div className="row">
                  <div
                    className={
                      styles.RobotContainer + " " + styles.checkboxDirection
                    }
                  >
                    <label htmlFor="rememberMe" className={styles.robotLabel}>
                      I'm not Robot
                    </label>
                    <input
                      type="checkbox"
                      name="rememberMe"
                      id="rememberMe"
                      className={styles.robotCheckbox}
                    />
                  </div>
                </div>
                <div className={"form-group " + styles.form}>
                  <input
                    className={
                      "btn btn-primary " +
                      styles.button +
                      " " +
                      styles.buttonColor
                    }
                    type="submit"
                    value="Sign up"
                  />
                </div>
              </form>
              <hr />
              <div className={styles.alternative}>
                <div className={"row " + styles.question}>
                  <p>You have an Account?</p>
                </div>
                <div className={"row form-group " + styles.buttonContainer}>
                  <button
                    className={"btn btn-warning " + styles.button}
                    onClick={() => navigate("/login")}
                  >
                    Log in
                  </button>
                </div>
              </div>
              <div className={styles.homepageLink}>
                <Link to="/">Homepage</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
