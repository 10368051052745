import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MobileNavbar from "../MobileNavbar";
import MobileSidePanel from "../MobileSidePanel";
import styles from "./MobileDashboard.module.css";
import AddProjectPopup from "../../AddProjectPopup/AddProjectPopup";

function MobileDashboard({ videoThumbnails }) {
  const navigate = useNavigate();
  const [popupShow, setPopupShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const newProjectPop = () => {
    setPopupShow(true);
  };

  const handleClose = () => {
    setPopupShow(false);
  };

  const slideClass = () => {
    setIsOpen(true);
  };

  const slideOut = () => {
    setIsOpen(false);
  };

  const sidePanelClass = isOpen
    ? styles.sidePanelContainer + " " + styles.openMenu
    : styles.sidePanelContainer;

  return (
    <div className={styles.mainContainer}>
      <MobileNavbar setClass={slideClass} />
      <div className={sidePanelClass}>
        <MobileSidePanel />
      </div>
      <div className={styles.innerContainer} onClick={slideOut}>
        <div className={styles.projects}>
          <p className={styles.titleText}>Recent Projects</p>
          <hr className={styles.hrLine} />
          <div className={styles.newProject} onClick={newProjectPop}>
            <div className={styles.iconContainer}>
              <div className={styles.partsWrapper}>
                <div className={styles.iconWrapper}>
                  <FontAwesomeIcon
                    icon={faPlus}
                    className={styles.newProjectIcon}
                  />
                </div>
                <div className={styles.textWrapper}>
                  <p>Add new Project</p>
                </div>
              </div>
            </div>
          </div>

          {videoThumbnails.map((item) => (
            <div className={styles.projectWrapper} key={item.file_id}>
              <img
                src={item.thumbnail_dl_url}
                alt={item.filename}
                className={styles.videoThumb}
                onClick={() =>
                  navigate(`/${item.file_id}`, {
                    state: { filename: item.filename.slice(0, -4) },
                  })
                }
              />
            </div>
          ))}
        </div>
        <div className={styles.account}>
          <p className={styles.titleText}>Account Status</p>
          <hr className={styles.hrLine} />
          <div className={styles.middleDiv}></div>
        </div>
        <div className={styles.tutorials}>
          <p className={styles.titleText}>Tutorials</p>
          <hr className={styles.hrLine} />
          <div className={styles.bottomDiv + " " + styles.tutorialHeader}></div>
          <div className={styles.bottomDiv}></div>
          <div className={styles.bottomDiv}></div>
        </div>
      </div>
      <div className={styles.popupContainer}>
        <AddProjectPopup show={popupShow} onClose={handleClose} />
      </div>
    </div>
  );
}

export default MobileDashboard;
