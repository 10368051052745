import { useContext, useEffect, useRef, useState } from "react";
import AppDataContext from "../../contexts/AppDataContext";
import BoldItem from "../BoldItem";
import SingleColumnFocused from "../SingleColumnFocused";
import SingleColumnRow from "../SingleColumnRow/SingleColumnRow";
import SubtitleRow from "../SubtitleRow";
import styles from "./SubtitleList.module.css";

function SubtitleList() {
  const listRef = useRef();
  const { Subtitle, setPanelItemValue } = useContext(AppDataContext);
  const [activeItemId, setActiveItemId] = useState(""); // eslint-disable-next-line
  const [isSingleColumn, setIsSingleColumn] = useState(false);

  const scrollToItem = (index, colType) => {
    let stepSize = 100;
    let constantValue = 5;
    if (colType === "single") {
      stepSize = 120;
      constantValue = 0;
    }
    if (listRef.current) {
      // const containerHeight = listRef.current.clientHeight;
      // const scrollPosition =
      //   index * itemHeight - containerHeight / 2 + itemHeight / 2;
      // console.log(scrollPosition);
      const scrollPosition = index * stepSize + constantValue;

      listRef.current.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  const handleFocus = (item, index, colType) => {
    setActiveItemId(item.id);
    setPanelItemValue(item);
    scrollToItem(index, colType);
  };

  useEffect(() => {
    if (
      Subtitle[0]?.translated_transcript === "" &&
      Subtitle[1]?.translated_transcript === ""
    ) {
      setIsSingleColumn(true);
    }
  }, [Subtitle]);

  return (
    <div className={styles.mainContainer} ref={listRef}>
      <ul className={styles.ul}>
        {Subtitle &&
          Subtitle.map((item, index) =>
            isSingleColumn ? (
              item && item.id === activeItemId ? (
                <li key={`${item.id}`}>
                  <SingleColumnFocused item={item} />
                </li>
              ) : (
                <li
                  key={`${item.id}`}
                  className={styles.singleRow}
                  onFocus={() => handleFocus(item, index, "single")}
                >
                  <SingleColumnRow>{item}</SingleColumnRow>
                </li>
              )
            ) : item && item.id === activeItemId ? (
              <li key={`${item.id}`}>
                <BoldItem item={item} />
              </li>
            ) : (
              <li
                key={`${item.id}`}
                className={styles.liRow}
                onFocus={() => handleFocus(item, index, "double")}
              >
                <SubtitleRow>{item}</SubtitleRow>
              </li>
            )
          )}
      </ul>
    </div>
  );
}

export default SubtitleList;
