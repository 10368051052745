import ReactLoading from "react-loading";
import styles from "./ExportMessage.module.css";

function ExportMessage({ isPreparing, isReady }) {
  return (
    <div className={styles.mainContainer}>
      <div className={isReady ? styles.readyTextContainer : styles.displayNone}>
        <p className={styles.readyText}>Your Video is Ready, Click Again</p>
      </div>
      <div
        className={
          isPreparing ? styles.loadingStatusContainer : styles.displayNone
        }
      >
        <ReactLoading
          type="bars"
          color="#BCF2F6"
          height={50}
          width={50}
          className={styles.loadingStatus}
        />
        <p className={styles.loadingText}>Preparing Your Download ...</p>
      </div>
    </div>
  );
}

export default ExportMessage;
