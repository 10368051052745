import PureTime from "../PureTime";
import styles from "./SingleColumnRow.module.css";

function SingleColumnRow({ children }) {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.timeColumn}>
        <div className={styles.TimeContainer}>
          <PureTime time={children.start_time} property={"start_time"} />
        </div>
        <div className={styles.TimeContainer}>
          <PureTime time={children.end_time} property={"end_time"} />
        </div>
      </div>
      <div className={styles.transcriptColumn}>
        <textarea
          rows={4}
          name="original_transcript"
          className={styles.textarea}
          defaultValue={children.original_transcript}
        />
      </div>
    </div>
  );
}

export default SingleColumnRow;
